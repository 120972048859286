import * as React from "react";

export const RefreshIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18}>
    <path
      fill="currentColor"
      d="M9 13.5c-2.49 0-4.5-2.01-4.5-4.5S6.51 4.5 9 4.5c1.24 0 2.36.52 3.17 1.33L10 8h5V3l-1.76 1.76A6.003 6.003 0 0 0 9 3C5.69 3 3.01 5.69 3.01 9S5.69 15 9 15a5.98 5.98 0 0 0 5.9-5h-1.52c-.46 2-2.24 3.5-4.38 3.5z"
    />
  </svg>
);
