export const lang = {
  title: "Edinburgh Transport Visualisation",
  filters: "Filters",
  service: "Service ",
  type: "Type ",
  speedMin: "Speed min ",
  fleet: "Fleet number ",
  showing: "Showing ",
  vehicles: " vehicle/s",
  outOfService: "Out of service",
};
